// Import packages
import React from 'react';

// Import components
import logo from '../images/logo.svg';

// Define component
class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: true,
      showDeliveryAddress: false,
    };
    this.toggleDeliveryAddress = this.toggleDeliveryAddress.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  toggleDeliveryAddress() {
    this.setState((state) => ({
      showDeliveryAddress: !state.showDeliveryAddress,
    }), () => {
      this.onChangeHandler();
      window.scrollBy(0, 100);
    });
  }

  onChangeHandler() {
    const { isDisabled } = this.state;
    const inputs = Array.from(document.querySelectorAll('input[required]'));
    let shouldBeDisabled = false;

    for (const input of inputs) {
      if (input.value === '') {
        shouldBeDisabled = true;
        break;
      }
    }

    if (isDisabled !== shouldBeDisabled) {
      this.setState({ isDisabled: shouldBeDisabled });
    }

    return shouldBeDisabled;
  }

  onSubmitHandler(event) {
    event.preventDefault();

    const self = event.target;
    const inputs = Array.from(self.querySelectorAll('input, select'));
    const data = {};

    inputs.forEach((input) => {
      if (input.name) {
        data[input.name] = input.value;
      }
    });

    this.setState({ disabled: true }, () => {
      fetch('/api/v1/orders', {
        method: 'post',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then(() => window.location.href = '/bekraftelse')
        .catch((error) => console.error(error));
    });
  }

  render() {
    const { isDisabled, showDeliveryAddress } = this.state;

    return (
      <form className="form" method="post" onSubmit={this.onSubmitHandler}>
        <img className="form--logo" alt="Gordion" src={logo} />
        <h1>
          Beställning av kvittorullar
        </h1>
        <p>
          Här kan du beställa kvittorullar till dina Gordion-kiosker.
        </p>
        <fieldset className="form--fieldset">
          <h2>
            Beställning
          </h2>
          <div className="form--control">
            <label htmlFor="orderId" className="form--label">
              Artikelnummer
            </label>
            <div className="form--select">
              <select name="orderId" id="orderId">
                <option value="1210">
                  1210 Thermokvittorullar 80/120/25 (inomhuskiosker)
                </option>
                <option value="2110">
                  2110 Thermokvittorullar 80/120/25 (utomhuskiosker)
                </option>
              </select>
            </div>
          </div>
          <div className="form--control" data-required>
            <label htmlFor="orderQuantity" className="form--label">
              Antal kartonger
            </label>
            <input
              name="orderQuantity"
              id="orderQuantity"
              className="form--input"
              required
              onChange={this.onChangeHandler}
            />
            <small className="form--description">
              12 st rullar per kartong
            </small>
          </div>
        </fieldset>
        <fieldset className="form--fieldset">
          <h2>
            Kontaktperson
          </h2>
          <div className="form--control" data-required>
            <label htmlFor="contactName" className="form--label">
              Namn
            </label>
            <input
              name="contactName"
              id="contactName"
              className="form--input"
              required
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form--row">
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="contactEmail" className="form--label">
                  E-postadress
                </label>
                <input
                  name="contactEmail"
                  id="contactEmail"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="contactPhone" className="form--label">
                  Telnummer
                </label>
                <input
                  name="contactPhone"
                  id="contactPhone"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="form--fieldset">
          <h2>
            Fakturaadress
          </h2>
          <div className="form--row">
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="invoiceId" className="form--label">
                  Organisationsnummer
                </label>
                <input
                  name="invoiceId"
                  id="invoiceId"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="invoiceCompany" className="form--label">
                  Företag
                </label>
                <input
                  name="invoiceCompany"
                  id="invoiceCompany"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
          </div>
          <div className="form--control" data-required>
            <label htmlFor="invoiceAddress" className="form--label">
              Adress
            </label>
            <input
              name="invoiceAddress"
              id="invoiceAddress"
              className="form--input"
              required
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form--row">
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="invoicePostalCode" className="form--label">
                  Postnummer
                </label>
                <input
                  name="invoicePostalCode"
                  id="invoicePostalCode"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="invoiceCity" className="form--label">
                  Ort
                </label>
                <input
                  name="invoiceCity"
                  id="invoiceCity"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
          </div>
          <div className="form--row">
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="invoiceReference" className="form--label">
                  Referens
                </label>
                <input
                  name="invoiceReference"
                  id="invoiceReference"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            <div className="form--column">
              <div className="form--control">
                <label htmlFor="invoiceReferenceCode" className="form--label">
                  Referenskod
                </label>
                <input
                  name="invoiceReferenceCode"
                  id="invoiceReferenceCode"
                  className="form--input" />
              </div>
            </div>
          </div>
          <div className="form--control">
            <label className="form--label">
              Är leveransadressen samma som er fakturaadress?
            </label>
            <div className="form--select">
              <select onChange={this.toggleDeliveryAddress}>
                <option value="1">
                  Ja
                </option>
                <option value="0">
                  Nej
                </option>
              </select>
            </div>
          </div>
        </fieldset>
        {showDeliveryAddress && <fieldset className="form--fieldset">
          <h2>
            Leveransadress
          </h2>
          <div className="form--control" data-required>
            <label htmlFor="deliveryCompany" className="form--label">
              Företag
            </label>
            <input
              name="deliveryCompany"
              id="deliveryCompany"
              className="form--input"
              required
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form--row">
            <div className="form--column">
              <div className="form--control">
                <label htmlFor="deliveryName" className="form--label">
                  Kontaktperson för leverans
                </label>
                <input
                  name="deliveryName"
                  id="deliveryName"
                  className="form--input"
                />
              </div>
            </div>
            <div className="form--column">
              <div className="form--control">
                <label htmlFor="deliveryPhone" className="form--label">
                  Telnummer för leverans
                </label>
                <input
                  name="deliveryPhone"
                  id="deliveryPhone"
                  className="form--input" />
              </div>
            </div>
          </div>
          <div className="form--control" data-required>
            <label htmlFor="deliveryAddress" className="form--label">
              Adress
            </label>
            <input
              name="deliveryAddress"
              id="deliveryAddress"
              className="form--input"
              required
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form--row">
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="deliveryPostalCode" className="form--label">
                  Postnummer
                </label>
                <input
                  name="deliveryPostalCode"
                  id="deliveryPostalCode"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            <div className="form--column">
              <div className="form--control" data-required>
                <label htmlFor="deliveryCity" className="form--label">
                  Ort
                </label>
                <input
                  name="deliveryCity"
                  id="deliveryCity"
                  className="form--input"
                  required
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
          </div>
        </fieldset>}
        <fieldset className="form--fieldset">
          <button className="form--button" type="submit" disabled={isDisabled}>Skicka beställning</button>
        </fieldset>
      </form>
    );
  }
}

// Export component
export default Home;

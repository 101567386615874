// Import packages
import React from 'react';

// Import components
import logo from '../images/logo.svg';

// Define component
class Confirmation extends React.Component {
  render() {
    return (
      <>
        <img className="form--logo" alt="Gordion" src={logo} />
        <h1>
          Beställning av kvittorullar
        </h1>
        <p>
          Här kan du beställa pappersrullar til dina Gordion-kiosker.
        </p>
        <div className="form--fieldset">
          <p>
            Tack för din beställning. Vi kommer snart att ta kontakt.
          </p>
        </div>
      </>
    );
  }
}

// Export component
export default Confirmation;

// Import Internet Explorer 11 polyfill
import 'react-app-polyfill/ie11';

// Import packages
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Import styles
import './index.scss';

// Import views
import Home from './views/Home';
import Confirmation from './views/Confirmation';

// Define app component
const App = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/bekraftelse" component={Confirmation} />
  </Switch>
);

// Render app
ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));
